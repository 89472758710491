/* Sweet Alert */
.sweet-alert {
  margin: auto auto !important;
}

/* PSE Styles */

.App {
  background-color: white;
  font-family: "Roboto", sans-serif;
  font-weight: 400 !important;
  font-size: 16px !important;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif !important;
  font-weight: 300 !important;
  font-size: 42px !important;
}

.support-copy {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.s2 {
  font-family: "Roboto Condensed", sans-serif;
}

.toast {
  background-color: #ffc107 !important;
}

:root {
  --amplify-primary-color: #156570;
  --amplify-primary-tint: #156570;
  --amplify-primary-shade: #156570;
  --amplify-secondary-color: #474b55;
  --amplify-secondary-tint: #474b55;
  --amplify-secondary-shade: #474b55;
  --amplify-font-family: Roboto, sans-serif;
}

/* Other Styles */

.btn.btn-primary[disabled],
.btn.btn-warning[disabled],
.btn.btn-danger[disabled],
.btn.btn-success[disabled] {
  background-color: #666666;
  border-color: #666666;
  color: white;
}

.section-header {
  font-size: 1.5rem;
}

.header-text {
  max-width: 700px;
}

.pse-bg {
  background: #156570;
  color: #ffffff;
}

.main {
  padding: 150px 0 0 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.card2 {
  /* position: relative; */
  margin: 5% 0;
}

.custom-amp-container {
  height: 400px;
}

.card2 .custom-amp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

html {
  height: 100% !important;
}

#root,
body {
  height: 100% !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

main {
  background-color: white;
}

.modal-backdrop {
  opacity: 0.8 !important;
}

.section {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.section-end {
  padding-top: 3rem !important;
}
