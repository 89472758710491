/* sets normal link color */
footer a:link {
    color: #ffffff;
    text-decoration: underline;
}

/* sets visited link color */
footer a:visited {
    color: #CC33CC;
}

/* sets active link color */
footer a:active {
    color: #336666;
}

footer {
    margin: 45px 0 0 0;
    /* background: #156570; */
    background-color: #474b55;
    color: #ffffff;
    /* background-color: #474b55; */
    /* padding: 42px 0 36px; */
    padding: 25px 0;
}

small {
    font-family: 'Roboto', sans-serif!important;
    font-weight: 300!important;
    font-size: 12px!important;
}

footer h5 {
    font-family: 'Roboto Condensed', sans-serif;
    font-size:16px;
}

footer a {
    font-size:14px;
}

footer a:visited {
    color: #ffffff;
}